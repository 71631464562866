'use client';
import { Box, Container, Heading, Text } from '@chakra-ui/layout';
import Image from 'next/image';
import React from 'react';

import NotFoundBanner from '@/app/_images/not-found-banner.jpg';

import GoBackButton from './Goback';
import Styles from './NotFoundBanner.module.scss';

const NotFoundBannerSection = () => {
  return (
    <Box className={Styles.bannerWrapper}>
      <Box className={Styles.bannerImage}>
        <Image src={NotFoundBanner} alt="Not Found" width={1440} height={810} />
      </Box>
      <Container
        maxW={'1280'}
        width={'100%'}
        className={Styles.bannerContentWrapper}
      >
        <Box className={Styles.bannerContent}>
          <Box className={Styles.headingElement}>
            <Heading
              as="h1"
              className={Styles.titleXl}
              marginBottom={{ base: 6, md: 4 }}
            >
              Oops you&apos;re off the map.
            </Heading>
          </Box>
          <Text className={Styles.bannerText} marginBottom={8}>
            You&apos;ve taken a path that does not exist. <br /> But we have
            made it easy to go back.
          </Text>
          <GoBackButton />
        </Box>
      </Container>
    </Box>
  );
};

export default NotFoundBannerSection;
