'use client';
import { useRouter } from 'next/navigation';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';

const GoBackButton = () => {
  const router = useRouter();

  const handleClick = () => {
    if (
      document.referrer &&
      new URL(document.referrer).origin === window.location.origin
    ) {
      router.back();
    } else {
      router.push('/');
    }
  };

  return (
    <ButtonCustom
      onClick={handleClick}
      className="btnWhite"
      btnText="Go Back"
    />
  );
};

export default GoBackButton;
